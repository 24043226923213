.App {
  text-align: center;
}

/*@import url('https://css.gg/notes.css');*/
@import url('https://css.gg/attachment.css');

body{
  background-color: rgba(248, 248, 248, 0.959);
  padding: 1rem;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

p{
  float: left;
  margin-left: 0.4rem;
}

/* Imposta lo stile del contenitore fullscreen */
/* Imposta lo stile del contenitore fullscreen */
.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black; /* Puoi cambiare il colore di sfondo se necessario */
  z-index: 9999;
}

/* Imposta lo stile dell'immagine fullscreen */
.fullscreen-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /*image-rendering: -webkit-optimize-contrast; /* Migliora la qualità su WebKit */
 /* image-rendering: crisp-edges; /* Prova 'pixelated' o 'high-quality' per diversi risultati */
}
/*rgba(22, 22, 22, 0.37)*/
#loader_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(22, 22, 22, 0.37);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Assicurati che sia sopra altri elementi */
}


/* Stile per il loader */
.loader {
  position: absolute;
  bottom: 20px; /* Sposta il loader verso il basso */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Stile per le palline */
.dot {
  width: 15px; /* Aumenta la larghezza delle palline */
  height: 15px; /* Aumenta l'altezza delle palline */
  margin: 0 10px; /* Aumenta lo spazio tra le palline */
  background-color: white; /* Colore delle palline */
  border-radius: 50%;
  animation: bounce 0.6s infinite alternate;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5); /* Ombra interna */
}

/* Animazione per le palline */
@keyframes bounce {
  to {
    transform: translateY(-170%); /* Aumenta l'altezza dello spostamento */
  }
}

/* Delay per le palline */
.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}


/*
select {
  background-color: rgb(190, 190, 190);
  border: none;
  border-radius: 0.2rem;
}


label{
  float: left;
  margin-right: 1rem;
}


input 				{
  border:none;
  border-bottom:1px solid #757575;
  width: 4rem;
  border-radius: 0.2rem;
  background-color: rgb(190, 190, 190);
}

input:hover{
  background-color: #282c34;
  color:aliceblue;
}


#form{
  border-radius:1rem;
  margin-top: 0.5rem;
  text-align:center;
}*/



/**/
.topnav {
  overflow: hidden;
  background-color: rgb(190, 190, 190);
  position: sticky;
  width: 99%;
  margin-bottom: 0.5rem;
  height: 3rem
}

/*topnav links */
.topnav button {
  float: left;
  display: block;
  color: #000000;
  text-align: center;
  padding: 0.875rem 1rem;
  text-decoration: none;
  border: none;
  background-color: rgb(190, 190, 190);
  height: 3rem;
  width: auto;
  font-size: 0.8rem;


  


}

.topnav button:hover{
  background-color: rgb(102, 103, 105);
}

a{
  text-decoration: none;
  color: #000000;
}

iframe{
  margin-top: 0.8rem;
 
  border: 0px;
  width:100%;
  


}

.frame_div{
  
  align-items: center;
  text-align: center;
  height: 200rem;
}

/*
iframe{
  margin: 0rem;
  width: 100%;
  height: 100%;
  border: 0px;
  range: auto;

}*/
/*
#note_lingue{
  width: 100%;
  height: 2rem;
  word-wrap: break-word;
}

#note_lingue::placeholder{
  float:left;
  font-style: italic;
  word-wrap: break-word;
  font-size: auto;
  margin-left:0.2rem;
  margin-top:0.5rem;
  width:auto;
}

#titolo{
  text-align: justify;
}*/



@media screen and
(max-width: 400px){
 #label1{
  display:none;
 }
}

@media screen and
(max-width: 300px){
 label{
  display:none;
 }
}

.cella_select{
  margin-left: 0;
}

/*tabella*/
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  }

  table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
  }

  table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
  }

  table th,
  table td {
  padding: .625em;
  text-align: center;
  
  }

  table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
  }

  @media screen and (max-width: 600px) {
  table {
      border: 0;
  }

  table caption {
      font-size: 1.3em;
  }
  
  table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
  }
  
  table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
  }
  
  table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
  }
  
  table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
  }
  
  table td:last-child {
      border-bottom: 0;
  }
  }

  /*loader*/
  h2{
    color: #030303;
    text-align: center;
    font-family: sans-serif;
    text-transform: uppercase;
    font-size: 20px;
    position: relative;
  }
  
  h2:after {
    position: absolute;
    content: "";
    -webkit-animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
    animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
  }
  
  .loader {
    margin: 5% auto 30px;
  }
  
  .book {
    border: 2px solid #000000;
    width: 60px;
    height: 45px;
    position: relative;
    perspective: 150px;
  }
  
  .page {
    display: block;
    width: 30px;
    height: 45px;
    border: 2px solid #000000;
    border-left: 1px solid #000000;
    margin: 0;
    position: absolute;
    right: -4px;
    top: -4px;
    overflow: hidden;
    background: #afafaf;
    transform-style: preserve-3d;
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
  
  .book .page:nth-child(1) {
    -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.6s infinite;
    animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.6s infinite;
  }
  
  .book .page:nth-child(2) {
    -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.45s infinite;
    animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.45s infinite;
  }
  
  .book .page:nth-child(3) {
    -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.2s infinite;
    animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.2s infinite;
  }
  
  
  /* Page turn */
  
  @-webkit-keyframes pageTurn {
    0% {
      -webkit-transform: rotateY( 0deg);
      transform: rotateY( 0deg);
    }
    20% {
      background: #afafaf;
    }
    40% {
      background: rgb(168, 168, 168);
      -webkit-transform: rotateY( -180deg);
      transform: rotateY( -180deg);
    }
    100% {
      background: rgb(168, 168, 168);
      -webkit-transform: rotateY( -180deg);
      transform: rotateY( -180deg);
    }
  }
  
  @keyframes pageTurn {
    0% {
      transform: rotateY( 0deg);
    }
    20% {
      background: #afafaf;
    }
    40% {
      background: rgb(168, 168, 168);
      transform: rotateY( -180deg);
    }
    100% {
      background: rgb(168, 168, 168);
      transform: rotateY( -180deg);
    }
  }
  
  
  /* Dots */
  
  @-webkit-keyframes Dots {
    0% {
      content: "";
    }
    33% {
      content: ".";
    }
    66% {
      content: "..";
    }
    100% {
      content: "...";
    }
  }
  
  @keyframes Dots {
    0% {
      content: "";
    }
    33% {
      content: ".";
    }
    66% {
      content: "..";
    }
    100% {
      content: "...";
    }
  }